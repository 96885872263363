import request from '@/utils/request' 

// 查询图书列表
export function listBook(data) {
  return request({ 
    url: '/book/getAll',
    method: 'post',
    data
  })
}

// export function listBook(params) {
//   return request({
//     url: '/book/getAll',
//     method: 'post',
//     params
//   })
// }

// 查阅具体单本书籍
export function findOneBook(params){
  return request({
    url:'/book/findOne',
    method:'get',
    params
  })
}

// 添加图书
export function addBook(data) {
  return request({
    url: '/book/add',
    method: 'post',
    data
    
  })
}

// 修改图书
export function updateBook(data) {
  return request({
    url: '/book/edit',
    method: 'post',
    data
  })
}

// 删除图书
export function removeBook(data,type) {
  return request({
    url: `/book/remove?type=${JSON.stringify(type)}`,
    method: 'post',
    data
  })
}

// 获得所有图书位置
export function BookPosition(){
  return request({
    url:'/book/getAllBookPosition',
    method:'get'
  })
}

//通过isbn获取当前的图书数据

export function getISBN(data){
  return request({
    url:'/book/autocomplete',
    method: 'get',
   params:{
    isbn:data
   }
  })
}

//导出图书
export function exportBook(data){
  return request({
    url:'/book/export',
    method: 'POST',
    responseType: 'blob',
   
      headers: {
        'Content-Type': 'application/json'
     },
   
    
    data

  })



  // export function uploadImage(){
  //   return request({
  //     url:'/book/uploadImage',
  //     method: 'post',
  //     header:{
  //       headers: {
  //         'Content-Type': 'form-data'
  //      },
  //     },
  //    data
  //   })
  
}
//================ 读者操作 ==================

// // 借阅图书
// export function lendBook(params) {
//   return request({
//     url: '/book/lendBook',
//     method: 'get',
//     params
//   })
// }

// // 借阅图书
// export function returnBook(params) {
//   return request({
//     url: '/book/returnBook',
//     method: 'get',
//     params
//   })
// }
